import React, { useContext } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { breakpoints, tychoNavy } from '../../utils/theme'
import { formatPrice, ProductsName } from '../../utils/utils'
import { useTranslation } from 'react-i18next'
import { germany } from '../../initial/vars'
import { UsersContext } from '../../context'

const slugify = require('slugify')

const StyledCard = styled.div`
  background-color: transparent;
  color: ${tychoNavy || 'initial'};
  z-index: 9999;
  margin: 16px 16px 24px;
  position: relative;

  a {
    text-decoration: none;
    color: inherit;
  }

  @media (max-width: ${breakpoints.mobile + 'px'}) {
    margin: 8px 8px 18px;
  }
`

const CardBody = styled.div`
  font-size: 22.85px;
  line-height: 1.3;
  padding: 16px 10px 14px 0;
  height: auto;
  


  @media (max-width: ${breakpoints.mobile + 'px'}) {
    font-size: 17.2px;
  }

 
`

const BulletPoints = styled.div`
  opacity: .5;
  margin: 10px 0;
  font-size: 16px;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis;

  @media (max-width: ${breakpoints.mobile + 'px'}) {
    font-size: 14px;
    margin: 7px 0;
  }

`

const PriceWrapper = styled.div`
  font-size: 16.67px;
  position: absolute;
  bottom: -13px;

  @media (max-width: ${breakpoints.mobile + 'px'}) {
    font-size: 14px;
    bottom: -9px; 
  }
`

const ProductCard = ({ product }) => {
  const { region } = useContext(UsersContext)

  const { t, i18n } = useTranslation()
  const slug = slugify(t(`product::${product?.name}`), { lower: true }).replace(/[^a-z0-9-]/gi, '')
  const curretnLang = i18n.language
  const prefix = germany ? curretnLang + '/' : ''

  const price = germany ? product.acf.euro_price : product.price

  return (
    <StyledCard>

      <Link to={`/${prefix}${ProductsName(curretnLang)}/${slug}/`}>
        {product.images
          .filter(image => image.localFile !== null)
          .slice(0, 1)
          .map(image => (
            <Img
              fluid={image.localFile.childImageSharp.fluid}
              key={image.localFile.childImageSharp.fluid.src}
            />
          ))}

        <CardBody>
          {t(`product::${product.name}`)} <br />
          <BulletPoints>
            {product.acf && product.acf.bullet_1 && (<> {t(product.acf.bullet_1)} <br /> </>)}
            {product.acf && product.acf.bullet_2 && (<> {t(product.acf.bullet_2)} <br /> </>)}
            {product.acf && product.acf.bullet_3 && (<> {t(product.acf.bullet_3)} <br /> </>)}
          </BulletPoints>
          <PriceWrapper>
            {product.tags.some(tag => tag.name === 'gravity') && t('od ')}
            {formatPrice({ price, region })}
          </PriceWrapper>
        </CardBody>

      </Link>
    </StyledCard>
  )
}


export default ProductCard